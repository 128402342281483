import { render, staticRenderFns } from "./EducationProviders.vue?vue&type=template&id=e7a1ef18&scoped=true&"
import script from "./EducationProviders.vue?vue&type=script&lang=ts&"
export * from "./EducationProviders.vue?vue&type=script&lang=ts&"
import style0 from "./EducationProviders.vue?vue&type=style&index=0&id=e7a1ef18&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7a1ef18",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProviderItem: require('/var/www/dockerize-nuxt/agora-app/components/provider/ProviderItem.vue').default})
