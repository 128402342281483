import { render, staticRenderFns } from "./LandingMessage.vue?vue&type=template&id=d45f7ba2&scoped=true&"
import script from "./LandingMessage.vue?vue&type=script&lang=ts&"
export * from "./LandingMessage.vue?vue&type=script&lang=ts&"
import style0 from "./LandingMessage.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./LandingMessage.vue?vue&type=style&index=1&id=d45f7ba2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d45f7ba2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NotifyEmailInput: require('/var/www/dockerize-nuxt/agora-app/components/NotifyEmailInput.vue').default})
