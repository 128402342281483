


























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import ImageCard from '@/components/shared/ImageCard.vue'
import { HomePageWhatWeDoDto } from '~/apis'

@Component({
  components: {
    ImageCard
  }
})

export default class StudentProgrammerComponent extends Vue {
  @Prop() image!: string
  @Prop() title!: string
  @Prop() description!: string
  @Prop({ type: Array, default: () => [] }) homepageWhatWeDoResponse!: HomePageWhatWeDoDto[]

  onClickWholeCard () {
    this.$emit('onClickWholeCard')
  }
}
