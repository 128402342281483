




































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class EducationProviderList extends Vue {
  @Prop({ default: () => [] }) providerList!: any[]
  @Prop() title!: string
  @Prop() description!: string

  viewEp (slug: string) {
    return `/courses/providers/${slug}`
  }

  get validProviderList () {
    return this.providerList.filter(item => !!item.slug)
  }
}
