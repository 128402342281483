


















































import { Vue, Component, Prop } from 'vue-property-decorator'
import VueSlickCarousel from 'vue-slick-carousel'

@Component({
  components: { VueSlickCarousel }
})
export default class Slider extends Vue {
  @Prop() sliderList!: any[];
  @Prop({ type: Boolean }) isFullImage?: boolean;
  @Prop({ default: '' }) title!: string

  backgroundMain = this.sliderList[0].backgroundMain
  activeItem:number = 0
  carouselSetting = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    fade: true
  }

  mounted () {
    const mySlider = document.getElementsByClassName('mySlider')[0] as HTMLElement
    if (mySlider) {
      const defaultItems = mySlider.querySelectorAll('ul.slick-dots li')
      this.changeDotColor(this.sliderList[0].dotColor, this.sliderList[0].dotColorActive)
      const length = defaultItems.length
      for (let index = 0; index < length; index++) {
        const temp = defaultItems[index]as HTMLElement
        if (screen.width <= 376) {
          temp.style.width = '24px'
        } else {
          temp.style.width = '54px'
        }
      }
    }
  }

  beforeChange (_oldSlideIndex:number, newSlideIndex:number) {
    const currentSlider = this.sliderList[newSlideIndex]
    this.backgroundMain = currentSlider.backgroundMain
    this.activeItem = newSlideIndex
    this.changeDotColor(currentSlider.dotColor, currentSlider.dotColorActive)
  }

  changeDotColor (color: string, activeColor:string) {
    const customDot = document.getElementsByClassName('custom-dot') as unknown as HTMLElement[]
    const length = customDot.length
    for (let i = 0; i < length; i++) {
      const element = customDot[i]
      if (i === this.activeItem) {
        element.style.backgroundColor = activeColor
      } else {
        element.style.backgroundColor = color
      }
    }
  }
}
