















































































import { Component, Vue } from 'vue-property-decorator'
import VueSlickCarousel from 'vue-slick-carousel'
import { StrapiApi, CampaignsApi, ShowcaseCampaignPanelDto, CampaignShowcaseDto } from '~/apis'

@Component({
  components: {
    VueSlickCarousel
  }
})
export default class CampaignShowCasePanelComponent extends Vue {
  campaignShowcasePanel = {} as ShowcaseCampaignPanelDto
  promoList: CampaignShowcaseDto = []
  carouselSetting = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 5000,
    cssEase: 'ease-in-out',
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 640,
        settings: {
          dots: true,
          arrows: false
        }
      }
    ]
  }

  activeItem: number = 0

  async mounted () {
    await Promise.all([
      this.getImageBackgroundCampaignShowCase(),
      this.getPromoList()
    ])
    this.stylingSliderDots()
    window.addEventListener('resize', () => this.stylingSliderDots())
  }

  beforeDestroy () {
    window.removeEventListener('resize', () => this.stylingSliderDots())
  }

  updated () {
    this.stylingSliderDots()
  }

  private stylingSliderDots () {
    const mySlider = document.getElementsByClassName('my-slider')[0] as HTMLElement
    const defaultItems = mySlider.querySelectorAll('ul.slick-dots li')
    const length = defaultItems.length
    for (let index = 0; index < length; index++) {
      const temp = defaultItems[index]as HTMLElement
      if (screen.width <= 425) {
        temp.style.width = '24px'
      } else {
        temp.style.width = '54px'
      }
    }
  }

  async getImageBackgroundCampaignShowCase () {
    await new StrapiApi().getCampaignShowCasePanel()
      .then((val:any) => {
        const { data: { data } } = val
        this.campaignShowcasePanel = data
      })
  }

  async getPromoList () {
    await new CampaignsApi().getPromoList()
      .then((val: any) => {
        const { data: { data } } = val
        this.promoList = data
      })
  }

  onRedirect () {
    this.$router.push('/offers')
  }

  beforeChange (_oldSlideIndex:number, newSlideIndex:number) {
    this.activeItem = newSlideIndex
  }
}
