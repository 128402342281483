

























































































import { Component, Vue } from 'vue-property-decorator'
import {
  StrapiApi,
  LandingMessageDto
} from '../../apis'

@Component({
  components: {}
})
export default class LandingMessage extends Vue {
  isShowModal :boolean = true
  landingMessageContent: LandingMessageDto = {
    title: '',
    imageUrl: '',
    description: '',
    moreInfo: '',
    parentText: '',
    providerText: '',
    followUsText: '',
    isShowLandingMessage: false,
    clockIcon: '',
    time: '',
    locationIcon: '',
    location: '',
    facebookIcon: '',
    facebookLink: '',
    instagramIcon: '',
    instagramLink: '',
    linkedInIcon: '',
    linkedInLink: '',
    twitterIcon: '',
    twitterLink: '',
    tikTokIcon: '',
    tikTokLink: '',
    textColor: '',
    backgroundColor: '',
    backgroundImage: ''
  }

  beforeMount () {
    (new StrapiApi().getLandingMessageContent())
      .then((val) => {
        this.landingMessageContent = val.data.data
      })
  }

  closeModal () {
    this.isShowModal = !this.isShowModal
  }
}
