








































import { BookOpenIcon, CurrencyDollarIcon, ClockIcon } from '@vue-hero-icons/solid'
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ProviderSummaryDto } from '~/apis'

@Component({
  components: { BookOpenIcon, CurrencyDollarIcon, ClockIcon }
})
export default class ProviderItem extends Vue {
  @Prop({ required: true }) provider!: ProviderSummaryDto
  @Prop({ default: '/' }) redirectUrl!: string

  encodedProviderSlug (slug: string) {
    return slug ? encodeURIComponent(slug) : ''
  }
}
