





































import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class HeaderImageCardComponent extends Vue {
  @Prop() image!: string
  @Prop() title!: string
  @Prop() category!: string
  @Prop() description!: string
  @Prop() backgroundColor!: string
  @Prop() path!: string

  onCardClick () {
    this.$router.push(this.path)
  }
}
