































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import {
  GetProvidersResponseData,
  HomePageContentDto,
  HomePageWhatWeDoDto,
  HomePageImageDto
} from '~/apis'
import LandingMessage from '~/components/content/LandingMessage.vue'
import Overview from '~/components/content/Overview.vue'
import Feature from '~/components/content/Feature.vue'
import Subjects from '~/components/content/Subjects.vue'
import TickerTape from '~/components/content/TickerTape.vue'
import Slider from '~/components/content/Slider.vue'
import EducationProviders from '~/components/content/EducationProviders.vue'
import Location from '~/components/content/Location.vue'
import Feedbacks from '~/components/content/Feedbacks.vue'
import PromptMessage from '~/components/modals/PromptMessageAfterSignedUp.vue'
import CampaignShowcasePanel from '~/components/campaigns/CampaignShowcasePanel.vue'
import SampleStudentSchedule from '~/components/schedule/SampleStudentSchedule.vue'
import SliderCard from '~/components/shared/SliderCard.vue'
import TheSpace3D from '~/components/content/TheSpace3D.vue'
import EducationProvidersList from '~/components/content/EducationProvidersList.vue'
import StudentProgrammer from '~/components/shared/StudentProgrammer.vue'
import HomepageHeroRework from '~/components/content/HomepageHeroRework.vue'

@Component({
  components: {
    LandingMessage,
    Overview,
    Feature,
    Subjects,
    TickerTape,
    Slider,
    EducationProviders,
    Location,
    Feedbacks,
    PromptMessage,
    CampaignShowcasePanel,
    SampleStudentSchedule,
    SliderCard,
    TheSpace3D,
    EducationProvidersList,
    StudentProgrammer,
    HomepageHeroRework
  }
})
export default class HomePage extends Vue {
  @Prop() providersResponse!: GetProvidersResponseData
  @Prop() isShow!: boolean
  @Prop() isShowPromptMessage!: boolean
  @Prop() homepageWhatWeDo!: HomePageWhatWeDoDto
  @Prop() homepageImage!: HomePageImageDto

  @Prop() prevPage!: string
  @Prop() loggedIn!: boolean

  @Prop() homepageContents!: HomePageContentDto

  @Prop() heroImage!: string
}
