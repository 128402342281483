






































import VueSlickCarousel from 'vue-slick-carousel'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: { VueSlickCarousel }
})
export default class Feedbacks extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: () => [] }) feedbackList!: any[]

  activeItem:number = 0

  carouselSetting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          cssEase: 'linear'
        }
      }
    ]
  }

  mounted () {
    const mySlider = document.getElementsByClassName('feedback-slider')[0] as HTMLElement
    const defaultItems = mySlider.querySelectorAll('ul.slick-dots li')
    const length = defaultItems.length
    for (let index = 0; index < length; index++) {
      const temp = defaultItems[index]as HTMLElement
      if (screen.width <= 376) {
        temp.style.width = '24px'
      } else {
        temp.style.width = '54px'
      }
    }
  }

  beforeChange (_oldSlideIndex:number, newSlideIndex:number) {
    this.activeItem = newSlideIndex
  }
}
