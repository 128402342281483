




















/* eslint-disable no-new */
import { Vue, Component, Prop } from 'vue-property-decorator'
declare let google: any

@Component({
  components: { }
})
export default class Location extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) description!: string

  mounted () {
    this.initMap()
  }

  initMap () {
    let googleMapScript = document.getElementById('googleMaps')
    if (!googleMapScript) {
      googleMapScript = document.createElement('script')
      googleMapScript.setAttribute('id', 'googleMaps')
      googleMapScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=' + process.env.GOOGLE_MAP_API_KEY + '&libraries=places')
      document.head.appendChild(googleMapScript)
      googleMapScript.onload = () => {
        this.loadMap()
      }
    } else {
      this.loadMap()
    }
  }

  loadMap () {
    const myLatLng = { lat: 1.2642284406495652, lng: 103.82035796866408 }
    const map = new google.maps.Map(document.getElementById('map'), {
      center: myLatLng,
      zoom: 18
    })
    const marker = new google.maps.Marker({ position: myLatLng, map, title: 'Agora Colearning' })

    const contentString =
    '<div id="content" class="poi-info-window gm-style">' +
    '<div class="title full-width">Agora Colearning</div>' +
    '<div id="bodyContent" class="address">' +
    "<p jstcache='4' class='address-line full-width'>1 Maritime Square</p>" +
    '<p jstcache="4" class="address-line full-width">#04-01</p>' +
    '<p jstcache="4" class="address-line full-width">Singapore 099253</p>' +
    '<p><a href="https://www.google.com/maps/place/Agora+Colearning/@1.2642175,103.8203582,15z/data=!4m2!3m1!1s0x0:0xe056400811da3322?sa=X&ved=2ahUKEwiXr_yp4K_4AhUgSGwGHb7ICrMQ_BJ6BAg0EAU" target="_blank">' +
    "View on Google Maps</a> " +
    "</div>" +
    "</div>";

    const infoWindow = new google.maps.InfoWindow({
      content: contentString
    })

    infoWindow.open(map, marker)

    google.maps.event.addListener(marker, 'click', () => {
      infoWindow.open(map, marker)
    })
  }
}
