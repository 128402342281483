









































































import VueSlickCarousel from 'vue-slick-carousel'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ChevronLeftIcon, ChevronRightIcon, ClockIcon, AcademicCapIcon } from '@vue-hero-icons/solid'
import moment from 'moment'
import { CourseEventsHomepageDto } from '~/apis'
import { convertToSlug, lowerCaseText } from '~/utils/functions'
import CardItem from "~/components/whats-happening/CardItem.vue"

@Component({
  components: { CardItem, ChevronLeftIcon, ChevronRightIcon, ClockIcon, AcademicCapIcon, VueSlickCarousel }
})
export default class SliderCard extends Vue {
  @Prop({ type: Array, default: () => [] }) courseEventsResponse!: CourseEventsHomepageDto[]

  formatDate (datetime: string) {
    return moment(datetime).format('ddd, DD MMM YYYY @h:mma')
  }

  onClickCourseDetailsEvent (courseSlug: string, courseInstanceReferenceId: string) {
    const slug = courseSlug ? courseSlug + '/' : ''
    const referenceId = courseInstanceReferenceId ? lowerCaseText(courseInstanceReferenceId) + '/' : ''
    return `/courses/${slug}${referenceId}`
  }

  onClickViewAllEvents () {
    const coursePeriodIds: string[] = []
    this.courseEventsResponse.forEach((item: any) => {
      if (!coursePeriodIds.includes(item.coursePeriodId)) {
        coursePeriodIds.push(item.coursePeriodId)
      }
    })
    return `/courses/?courseperiods=${JSON.stringify(coursePeriodIds)}`
  }

  next (val: string) {
    const nexts = document.querySelector(`div.${val} > button.slick-arrow.slick-next`)
    if (nexts) {
      (nexts as HTMLButtonElement).click()
    }
  }

  previous (val: string) {
    const prevs = document.querySelector(`div.${val} > button.slick-arrow.slick-prev`)
    if (prevs) {
      (prevs as HTMLButtonElement).click()
    }
  }

  index: number = 0
  carouselSetting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          variableWidth: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          rows: 2
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2
        }
      }
    ]
  }
}
