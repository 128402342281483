


































import { Vue, Component, Prop } from 'vue-property-decorator'
import { StrapiApi, PromptMessageAfterSignedUpDto, CampaignsApi } from '~/apis'
import { formatCurrency } from '~/utils/functions'

@Component
export default class PromptMessageAfterSignedUp extends Vue {
  @Prop({ default: false }) isShowPromptMessage!: boolean

  isShow: boolean = true

  contentData: PromptMessageAfterSignedUpDto = {
    background: '',
    backgroundColor: '',
    textColor: '',
    image: '',
    title: '',
    description: ''
  }

  welcomeMessage: string = ''

  beforeMount () {
    const loading = this.$loading.show()
    if (this.$store.state.promoCode && this.$store.state.promoCode !== '') {
      new CampaignsApi().getWelcomeMessage(this.$store.state.promoCode).then((item: any) => { this.welcomeMessage = item?.data })
    }
    if (this.$store.state.referralParams && this.$store.state.referralParams.earnedCredit) {
      this.welcomeMessage += `Welcome to Agora Colearning, we have added a ${formatCurrency(this.$store.state.referralParams?.earnedCredit)} credit to your account which can be used when you purchase over ${formatCurrency(this.$store.state.referralParams?.minPurchaseAmount)} in a single transaction.`
    }
    new StrapiApi().getPromptMessageAfterSignedUp()
      .then((val) => {
        const { data } = val.data
        this.contentData = data
      })
      .finally(() => loading.hide())
  }

  beforeDestroy () {
    this.closeModal()
  }

  closeModal () {
    this.isShow = false
    this.$store.commit('setNewSignUp', false)
    this.$store.commit('setPromoCode', '')
    this.$store.commit('setReferralParams', {})
  }
}
