
























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ClockIcon, AcademicCapIcon } from '@vue-hero-icons/solid'
import moment from 'moment'
import { CourseEventsHomepageDto } from '~/apis'

@Component({
  components: {
    ClockIcon,
    AcademicCapIcon
  }
})
export default class CardItem extends Vue {
  @Prop() data!: CourseEventsHomepageDto;

  isMobile: boolean = true

  mounted () {
    this.isMobile = window.innerWidth < 640
  }

  formatDate (datetime: string) {
    return !this.isMobile ? moment(datetime).format('ddd, DD MMM YYYY @h:mma') : moment(datetime).format('ddd, DD MMM YYYY')
  }
}
