












































import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({
  components: { }
})
export default class TickerTape extends Vue {
  @Prop({ default: () => [] }) providerList!: any[]

  timeStep: number = 5

  animationTime (imageNumber: number) {
    return (imageNumber * this.timeStep) + 's'
  }

  viewEp (slug: string) {
    return `/courses/providers/${slug}`
  }
}

