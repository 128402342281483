





























import { Component, Vue, Prop } from 'vue-property-decorator'
import { sleep } from '~/utils/functions'

@Component({
  components: {}
})
export default class TheSpace3DList extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) description!: string

  mounted () {
    {/* this.removeFreePhotoGallery() */}
  }

  get matterPortUrl () {
    return process.env.MATTERPORT_URL
  }

  async removeFreePhotoGallery () {
    if (process.client) {
      let count = 0

      while (count < 5) {
        count++
        const element = document.querySelector('.elfsight-app-24952cac-66ce-4ba6-9c9e-e3936b25257a .jsx-3181866403 a')
        await sleep(500)
        if (element) {
          element.remove()
          break
        }
      }
    }
  }
}
