import { render, staticRenderFns } from "./StudentProgrammer.vue?vue&type=template&id=5ad28baa&scoped=true&"
import script from "./StudentProgrammer.vue?vue&type=script&lang=ts&"
export * from "./StudentProgrammer.vue?vue&type=script&lang=ts&"
import style0 from "./StudentProgrammer.vue?vue&type=style&index=0&id=5ad28baa&scoped=true&lang=css&"
import style1 from "./StudentProgrammer.vue?vue&type=style&index=1&id=5ad28baa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ad28baa",
  null
  
)

export default component.exports