











































import { Vue, Component, Prop } from 'vue-property-decorator'
import { StrapiApi, BrowseCourseButtonDto, HomePageImageDto } from '~/apis'
import NotifyEmailInput from '~/components/NotifyEmailInput.vue'

@Component({
  components: { NotifyEmailInput }
})
export default class Overview extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) description!: string
  @Prop({ default: '' }) image!: string
  @Prop({ default: '' }) backgroundImage!: string
  @Prop({ default: '' }) heroTextColor!: string
  @Prop({ default: '' }) notifyTitle!: string
  @Prop({ default: '' }) notifyDesc!: string
  @Prop({ default: '' }) notifyDescLink!: string
  @Prop({ default: '' }) notifyTextLink!: string
  @Prop() browseCourseButton!: BrowseCourseButtonDto
  @Prop() homepageImage!: HomePageImageDto

  email = '' as string
  bookAVisitBtnContent = '' as string

  created () {
    this.getBookAVisitButton()
  }

  getBookAVisitButton () {
    new StrapiApi().getBookAVisitExplanations()
        .then((val: any) => {
          const { data: { data } } = val
          this.bookAVisitBtnContent = data.button
        })
  }

  get autheticated () {
    return this.$store.state.authenticated
  }
}
