



























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ChevronLeftIcon, ChevronRightIcon } from '@vue-hero-icons/solid'
import VueSlickCarousel from 'vue-slick-carousel'
import { ProviderSummaryDto } from '~/apis'
import ProviderItem from '~/components/provider/ProviderItem.vue'

@Component({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    VueSlickCarousel,
    ProviderItem
  }
})
export default class EducationProviders extends Vue {
  @Prop({ required: true, default: [] }) providerList!: ProviderSummaryDto[]
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) description!: string

  carouselSetting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          variableWidth: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          variableWidth: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          rows: 1
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1
        }
      }
    ]
  }

  nextSlide () {
    (this.$refs.carouselProvides! as any).next()
  }

  prevSlide () {
    (this.$refs.carouselProvides! as any).prev()
  }
}
