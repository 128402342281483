





























import { Component, Vue } from 'nuxt-property-decorator'
import { StrapiApi, SampleStudentScheduleDto } from '~/apis'
@Component({})
export default class SampleStudentSchedule extends Vue {
  sampleScheduleContent = {} as SampleStudentScheduleDto

  mounted () {
    this.getSampleStudentScheduleContent()
  }

  getSampleStudentScheduleContent () {
    new StrapiApi().getSampleStudentScheduleContent()
      .then((val: any) => {
        const { data: { data } } = val
        this.sampleScheduleContent = data
      })
  }
}
