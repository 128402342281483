









































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { BrowseCourseButtonDto, HomePageImageDto, StrapiApi, StrapiHomepageButtonDto } from '~/apis'

@Component({
  components: {
  }
})
export default class HomepageReworkHero extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) description!: string
  @Prop({ default: '' }) image!: string
  @Prop({ default: '' }) backgroundImage!: string
  @Prop({ default: '' }) heroTextColor!: string
  @Prop({ default: '' }) notifyTitle!: string
  @Prop({ default: '' }) notifyDesc!: string
  @Prop({ default: '' }) notifyDescLink!: string
  @Prop({ default: '' }) notifyTextLink!: string
  @Prop() browseCourseButton!: BrowseCourseButtonDto
  @Prop() homepageImage!: HomePageImageDto
  @Prop() heroImage!: string
  @Prop({ default: undefined }) primaryButton?: StrapiHomepageButtonDto
  @Prop({ default: undefined }) ghostButton?: StrapiHomepageButtonDto

  email = '' as string
  bookAVisitBtnContent = '' as string

  created () {
    this.getBookAVisitButton()
  }

  async getBookAVisitButton () {
    await new StrapiApi().getBookAVisitExplanations()
      .then((val: any) => {
        const { data: { data } } = val
        this.bookAVisitBtnContent = data.button
      })
  }

  get autheticated () {
    return this.$store.state.authenticated
  }

  onClickHomepageButton (url: string) {
    const element = document.querySelector(url)

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }
}
