











































































































































import VueSlickCarousel from 'vue-slick-carousel'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ChevronLeftIcon, ChevronRightIcon, BookOpenIcon, CurrencyDollarIcon } from '@vue-hero-icons/solid'
import { chain, orderBy } from 'lodash'
import moment from 'moment'
import { SubjectDto, SubjectSummaryDto } from '~/apis'
import { formatCurrency } from '~/utils/functions'

@Component({
  components: { ChevronLeftIcon, ChevronRightIcon, BookOpenIcon, CurrencyDollarIcon, VueSlickCarousel }
})
export default class Subjects extends Vue {
  @Prop() subjects!: SubjectDto[]
  @Prop() summaryInfo!: SubjectSummaryDto[]
  @Prop({ default: '' }) title!: string

  carouselSetting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          variableWidth: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          rows: 2
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2
        }
      }
    ]
  }

  subjectTypeStyle: { type: string, style: string}[] = []

  get isHomePage () {
    return this.$route.name === 'index'
  }

  get summaryInfoMap () {
    return chain(this.summaryInfo).keyBy('subjectId').value()
  }

  get subjectTypes () {
    if (this.subjects && this.subjects.length > 0) {
      const typeSet = new Set<string>()
      this.subjects.forEach((val: SubjectDto) => {
        val.subjectType?.forEach((type) => {
          if (type && type.trim() !== '' && type.toUpperCase() !== 'WELLBEING') {
            typeSet.add(type)
          }
        })
      })
      return ([...typeSet] as string[]).sort((a, b) => {
        if (a < b) {
          return 1
        }

        if (a > b) {
          return -1
        }

        return 0
      })
    }

    return []
  }

  next (val: string) {
    const nexts = document.querySelector(`div.${val} > button.slick-arrow.slick-next`)
    if (nexts) {
      (nexts as HTMLButtonElement).click()
    }
  }

  previous (val: string) {
    const prevs = document.querySelector(`div.${val} > button.slick-arrow.slick-prev`)
    if (prevs) {
      (prevs as HTMLButtonElement).click()
    }
  }

  redirectLinkViewAll (type: string) {
    return '/courses?subjecttypes=' + JSON.stringify([type])
  }

  redirectLinkViewSubjectCourses (subjectId: string) {
    return '/courses?subjects=' + JSON.stringify([subjectId])
  }

  getSubjectBySubjectType (type: string): SubjectDto[] {
    const subjects: SubjectDto[] = orderBy(this.subjects.filter(item =>
      item.subjectType?.includes(type) &&
      item.id &&
      this.summaryInfoMap &&
      (this.summaryInfoMap[item.id].availability || 0) > 0
    ), (val) => {
      moment(val.createAt, "YYYY-MM-DD'T'HH:mm:ssZ")
    })
    if (!this.subjectTypeStyle.find(item => item.type === type)) {
      const isTwoLines = subjects.find(subject => subject.name && subject.name.length > 16)
      this.subjectTypeStyle.push({
        type,
        style: isTwoLines ? 'subject-name-height' : ''
      })
    }
    return subjects
  }

  getSubjects (type: string) {
    return orderBy(this.getSubjectBySubjectType(type), item => new Date(moment(item.newestCourseCreatedDate!).format('YYYY/MM/DD HH:mm')), 'desc')
  }

  formatPrice (number: string) {
    return formatCurrency(number, true)
  }
}
